// src/components/ServicesSection/ServicesSection.js
import React from 'react';
import residencialImage from "../assets/images/services/residence.jpg"
import commercialImage from "../assets/images/services/commercial.jpg"
import renovationImage from "../assets/images/services/renovation.jpg"
import planningImage from "../assets/images/services/planning.jpg"
import interiorImage from "../assets/images/services/interior.jpg"
import repairImage from "../assets/images/services/repair.jpg"
import sustainableImage from "../assets/images/services/sustainable.jpg"
import { motion } from "framer-motion"
const services = [
    {
        title: "Residential Construction",
        description: "We build durable, beautiful homes tailored to your lifestyle, ensuring high-quality construction from foundation to finishing touches.",
        icon: "🏠",
        image: residencialImage
    },
    {
        title: "Commercial Construction",
        description: "Comprehensive commercial services, from offices to retail spaces, designed to reflect your brand and operational needs.",
        icon: "🏢",
        image: commercialImage
    },
    {
        title: "Renovations and Remodeling",
        description: "Transform your space with our renovation expertise, including kitchen upgrades, bathroom makeovers, and full-home renovations.",
        icon: "🔨",
        image: renovationImage
    },
    {
        title: "Architectural Design and Planning",
        description: "Our experienced architects focus on creating functional, aesthetic spaces, working closely with clients to plan every detail.",
        icon: "📐",
        image: planningImage
    },
    {
        title: "Interior Design and Finishing",
        description: "From paint and flooring to custom cabinetry, we bring spaces to life with cohesive interiors that reflect your style.",
        icon: "🎨",
        image: interiorImage
    },
    {
        title: "Structural Repairs and Maintenance",
        description: "Keep your property safe and durable with our repair and maintenance services, from foundation to roofing.",
        icon: "🔧",
        image: repairImage
    },
    {
        title: "Sustainable and Eco-Friendly Construction",
        description: "We offer sustainable building options with energy-efficient materials, eco-friendly designs, and green certifications.",
        icon: "🌍",
        image: sustainableImage
    },
];

const ServicesSection = () => {
    return (
        <section className="relative z-10 p-10 tablet:pt-20 ">
            <div className=" mx-auto text-center max-w-7xl">
                <h2 className="text-4xl tablet:text-5xl tablet:mb-8 laptop:text-5xl laptop:mb-9 font-bold mb-6 ">Our Services</h2>
                <div className="grid gap-6 tablet:grid-cols-2 laptop:grid-cols-3">
                    {services.map((service, index) => (

                            <motion.div
                                initial={{ opacity: 0.3, scale: 0.95 }}
                                whileInView={{ opacity: 1, scale: 1 }}
                                transition={{ duration: 1 }}
                                key={index} className="bg-white block rounded-2xl overflow-hidden shadow-md hover:shadow-lg transition-shadow duration-300 relative  cursor-pointer" >
                                <img
                                    src={service.image}
                                    alt={service.title}
                                    className="w-full h-full object-cover"
                                />
                                {/* Padding should be as same as the card  */}
                                <div className="absolute py-3 border inset-0  w-full h-full flex flex-col items-center justify-center  overflow-hidden">
                                    <div className='py-28 bg-black bg-opacity-35'>

                                        <div className='bg-black bg-opacity-25 text-white '>

                                            {/* <div className="text-5xl mb-4">{service.icon}</div> */}
                                            <h3 className="text-xl font-semibold mb-2 ">{service.title}</h3>
                                            <p className="">{service.description}</p>
                                        </div>
                                    </div>
                                </div>
                            </motion.div>

                    ))}
                </div>
            </div>
        </section>
    );
};

export default ServicesSection;
