import { useState } from "react";
import { MdAddIcCall } from "react-icons/md";
import { MdAlternateEmail } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { IoTimeOutline } from "react-icons/io5";
import Swal from "sweetalert2";
import emailjs from "@emailjs/browser"
const contactDetails = {
    callUs: {
        title: "Call Us",
        numbers: ["+91 9908212850", "+91 9908212850"], // Changed to an array for better flexibility
        icon: <MdAddIcCall />
    },
    location: {
        title: "Our Location",
        area: "12-2-826/A/46",
        place: "Near Mehdipatnam Bus Depot, Hyderabad",
        icon: <FaLocationDot />
    },
    email: {
        title: "Our Email",
        emails: ["info@aplusconstructs.com"], // Changed to an array for better flexibility
        icon: <MdAlternateEmail />
    },
    workingHours: { // Changed the key to be unique
        title: "Working Hours",
        weekdays: "Mon-Fri: 10AM-5PM",
        weekend: "Sat-Sun: 10AM-1PM ",
        icon: <IoTimeOutline />
    },
};


function Contact( {pt}) {
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        phone: '',
        subject: '',
        message: '',
    });
    const [errors, setErrors] = useState({}); // State for tracking errors
    const [loading, setLoading] = useState(false); // New loading state
    const handleChange = (e) => {
        const { name, value } = e.target
        setFormData((prevFormData) => ({
            ...prevFormData, [name]: value
        }))
    };
    const validateForm = () => {
        let formErrors = {};
        let isValid = true;

        // Validate username
        if (!formData.username.trim()) {
            formErrors.username = "Name is required.";
            isValid = false;
        } else if (formData.username.length < 3) {
            formErrors.username = "Name must me atleast 3 characters"
            isValid = false
        }

        // Validate email
        if (!formData.email.trim()) {
            formErrors.email = "Email is required.";
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            formErrors.email = "Email is not valid.";
            isValid = false;
        }

        // Validate phone
        if (!/^\d{10}$/.test(formData.phone)) {
            formErrors.phone = "Phone number must be 10 digits.";
            isValid = false;
        }

        // Validate subject
        if (!formData.subject.trim()) {
            formErrors.subject = "Subject is required.";
            isValid = false;
        } else if (formData.subject.length < 10) {
            formErrors.subject = "Subject should be atleast 10 characters long"
            isValid = false
        }

        // Validate message
        if (!formData.message.trim()) {
            formErrors.message = "Message is required.";
            isValid = false;
        } else if (formData.message.length < 15) {
            formErrors.message = "Message should be atleast 15 characters long"
            isValid = false
        }

        setErrors(formErrors);
        return isValid;
    };
    const sendForm = async(e) => {
        e.preventDefault()
        // console.log("sendForm function triggered");  // Early log
        if (validateForm()) {
            setLoading(true); // Start loading animation
            try {
               //notification to admin
                // const adminResponse = 
                await emailjs.send(
                    process.env.REACT_APP_EMAILJS_SERVICE_ID,
                    process.env.REACT_APP_EMAILJS_TEMPLATE_ID_ADMIN,
                    {
                        username: formData.username,
                        email: formData.email,
                        phone: formData.phone,
                        subject: formData.subject,
                        message: formData.message,
                    },
                    process.env.REACT_APP_EMAILJS_USER_ID
                );
                // console.log("Admin response:", adminResponse);
                Swal.fire({
                    title: 'Your message has been sent! We\'ll get back to you shortly.',
                    // text: 'We will reach out to you soon',
                    icon: 'success',
                    confirmButtonText: 'Ok'
                })
                // You can also reset the form here if needed
                setFormData({
                    username: '',
                    email: '',
                    phone: '',
                    subject: '',
                    message: '',
                });
                setErrors({}); // Clear errors if needed
            } catch (e) {
                Swal.fire({
                    title: 'There was an issue sending your message. Please try again later.',
                    // text: 'Pls try again later',
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
        
            }finally {
                setLoading(false); // Stop loading animation
            }
        }else{

            Swal.fire({
                title: 'Pls fill the required fields',
                // text: 'Pls try again later',
                icon: 'error',
                confirmButtonText: 'Ok'
            })
        }

    }
    const splitLetters = (word) => {
        // Check if the word length is greater than 20
        if (word.length > 20) {
            // Get the first 20 characters
            const firstPart = word.slice(0, 24);
            // Get the remaining characters
            const secondPart = word.slice(24);
            // Return both parts as an array
            return [firstPart, secondPart];
        } else {
            // If the word is 20 characters or fewer, return it as the first part and an empty string as the second part
            return [word, ''];
        }
    };
    return (
        <section className={`tablet:px-20 px-10 ${pt? "pt-16" :"pt-48"}`}>
            <div className="header mx-auto max-w-[80vw] mb-5 ">
                <h1 className="text-4xl mb-4 font-semibold"><span className="pb-5 border-b-4 border-orange-500 inline-block ">Connect</span> With Us</h1>
                <p className="text-xl">We invite you to reach out and explore how seamlessly you can engage with our services. Timely communication is key, and we are here to assist you every step of the way.</p>
            </div>
            <div className=" tablet:pl-10  laptop:pl-20  flex flex-col mobile:max-w-full laptop:max-w-7xl mx-auto">
                <h1 className="text-4xl  mb-5">Send Us A Message</h1>
                <div className="flex flex-wrap laptop:flex-nowrap gap-10 mb-10 ">
                    <div className="left w-full laptop:w-3/4 ">


                        <form onSubmit={sendForm} method="post" className="">
                            {/* Name Field */}
                            <div className="mb-5">

                                <input type="text" id="username" name="username" placeholder="Name" value={formData.username} onChange={handleChange}
                                    className="py-3 px-5 border block  shadow-md w-full"
                                />
                                {errors.username && <span className="text-red-500">{errors.username}</span>}
                            </div>

                            {/* Email Field */}
                            <div className="mb-5">

                                <input type="email" id="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange}
                                    className="py-3 px-5 border block  shadow-md w-full"
                                />
                                {errors.email && <span className="text-red-500">{errors.email}</span>}
                            </div>

                            {/* Phone Field */}
                            <div className="mb-5">

                                <input type="number" id="phone" name="phone" placeholder="Contact Number (Optional)" value={formData.phone} onChange={handleChange}
                                    className="py-3 px-5 border block  shadow-md w-full"
                                />
                                {errors.phone && <span className="text-red-500">{errors.phone}</span>}
                            </div>

                            {/* Subject Field */}
                            <div className="mb-5">

                                <input type="text" id="subject" name="subject" placeholder="Subject" value={formData.subject} onChange={handleChange}
                                    className="py-3 px-5 border block  shadow-md w-full"
                                />
                                {errors.subject && <span className="text-red-500">{errors.subject}</span>}
                            </div>

                            {/* Message Field */}
                            <div className="mb-5">

                                <textarea id="message" name="message" placeholder="Message" value={formData.message} onChange={handleChange}
                                    className="py-3 px-5 border block  shadow-md min-h-32 w-full"
                                />
                                {errors.message && <span className="text-red-500">{errors.message}</span>}
                            </div>

                            <button type="submit" className="block bg-orange-500 px-20 py-4 rounded-lg hover:bg-gray-400 hover:text-black transition-all duration-200 hover:scale-105 text-white mobile:mx-auto laptop:mx-0">
                            {loading ? (
                                    <span>
                                        Sending
                                        <span className="dot-anim">.</span>
                                        <span className="dot-anim">.</span>
                                        <span className="dot-anim">.</span>
                                    </span>
                                ) : (
                                    "Send"
                                )}
                            </button>
                        </form>
                    </div>
                    <div className="right w-full ">
                        <h1 className="tablet:mb-4 text-3xl font-semibold pl-5 mobile:mb-7">Contact Info</h1>
                        <div className="grid grid-cols-1 gap-10 tablet:grid-cols-2 laptop:grid-cols-2 mb-10">
                            {Object.values(contactDetails).map((item, index) => {
                                return (
                                    <div className="flex" key={index}>
                                        <div className="icon p-4 text-white text-xl">
                                            <div className="bg-orange-500 rounded-full p-5">

                                                {item.icon}
                                            </div>
                                        </div>
                                        <div className="info">
                                            <h1 className="mb-3 font-semibold">{item.title}</h1>
                                            {item.numbers && item.numbers.map((number, idx) => ( // Checking if 'numbers' exists
                                            <a key={idx} href={`tel:${number}`} className="inline-block">{number}</a>
                                                
                                            ))}
                                            {item.emails && item.emails.map((email, idx) => ( // Checking if 'emails' exists
                                                <>
                                                    <a href={`mailto:${email}`} key={idx}  >{splitLetters(email)[0]}</a>
                                                    <a href={`mailto:${email}`} key={idx+1}  >{splitLetters(email)[1]}</a>
                                                </>
                                            ))}
                                            {item.area && <a href="https://maps.app.goo.gl/Qb3a685gP9WD5LFn9" target="_blank" rel="noreferrer">{item.area}, {item.place}</a>} {/* Displaying location */}
                                            {item.weekdays && <p>{item.weekdays}</p>} {/* Displaying weekdays */}
                                            {item.weekend && <p>{item.weekend}</p>} {/* Displaying weekend hours */}
                                        </div>
                                    </div>
                                )
                            })}



                        </div>
                        <div className="follow-section pl-5 mobile:text-center laptop:text-left">
                            <h1 className="font-semibold text-3xl mb-4">Follow Us</h1>
                            <div className="socials text-2xl flex mobile:w-full mobile:justify-center laptop:w-1/4 laptop:justify-between">
                                <a href="/contact" className="mr-3" ><FaWhatsapp className="inline-block text-green-600" /></a>
                                <a href="/contact" className="mr-3"><FaLinkedin className="inline-block text-blue-500" /></a>
                                <a href="/contact"><FaInstagram className="inline-block text-rose-500" /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Contact;