
import mazhar from "../assets/images/teams/mazhar.jpg"
import afsar from "../assets/images/teams/afsar.jpg"
import shahnawaz from "../assets/images/teams/shahnawaz.jpg"
import moiz from "../assets/images/teams/moiz.jpg"

export const Teams = () => {
    var teams = {
        1: {
            username: "Syed Moiz Adil",
            role: "CEO",
            phone: "+91 9908212850",
            img: moiz
        },
        2: {
            username: "Muhammed Mazhar khan",
            role: "Managing Director",
            phone: "+91 9908212850",
            img: mazhar
        },
        3: {
            username: "Mirza Afsar Baig",
            role: "Site Supervisor",
            phone: "+91 8328489817",
            img: afsar
        },
        4: {
            username: "Shahnawaz",
            role: "Site Engineer",
            phone: "+91 9346854969",
            img: shahnawaz
        },

    }
    return (
        <div className="px-4 py-16 mx-auto  laptop:px-24 laptop:py-20 ">
            <div className="mx-auto mb-10  text-center">
                <p className="inline-block  px-3 py-px mb-4 laptop:text-5xl font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
                    Know Our Team
                </p>
                <p className="text-base text-gray-700 tablet:text-lg">
                Our team of experienced professionals brings together expertise in design, engineering, and construction management to deliver exceptional results. Each member is dedicated to upholding the highest standards of quality, safety, and innovation, ensuring every project is completed on time and exceeds expectations. Together, we build more than structures—we build trust, reliability, and lasting partnerships.
   
                </p>
            </div>
            <div className="grid gap-10  mx-auto  p-5 tablet:grid-cols-2 laptop:grid-cols-2 large-desktop:grid-cols-4 laptop:max-w-screen">
                {Object.values(teams).map((member, index) => {
                    return (
                        <div className="grid grid-cols-3 ">
                            <div className="relative w-full h-30  rounded shadow ">
                                <img
                                    className="absolute object-cover bg-bottom w-full h-full rounded"
                                    src={member.img}
                                    alt="Person"
                                />
                            </div>
                            <div className="flex flex-col justify-center mt-0  p-5 col-span-2">
                                <p className="text-lg font-bold">{member.username}</p>
                                <p className="mb-4 text-xs text-gray-800">{member.role}</p>
                                <a href={`tel:${member.phone}`} className="mb-4 text-sm tracking-wide text-gray-800">
                                    {member.phone}
                                </a>
                                {/* <div className="flex items-center space-x-3">
                                    <a
                                        href="/"
                                        className="text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400"
                                    > social icon1
                                    </a>
                                    <a
                                        href="/"
                                        className="text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400"
                                    >  social icon2
                                    </a>
                                </div> */}
                            </div>
                        </div>
                    )
                })}


            </div>
        </div>
    );
};