import { FaWhatsapp } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import logo from "../assets/icons/brand.png"
function Footer() {
    return (
        <footer className=" bg-gray-800 text-white py-16 ">
            <div className="grid gap-5  tablet:items-center tablet:justify-items-center  grid-cols-1 tablet:grid-cols-2 laptop:grid-cols-4 mx-auto max-w-[80vw]">
                {/* Branding */}
                <div className="main">

                    <div className="brand mr-10">
                        <div className="img-container flex gap-5 items-center mb-5">
                            <img src={logo} alt="logo"  className="inline-block w-[50px]" />
                            <h1 className="inline-block text-2xl font-semibold ">A+ <span>CONSTRUCTS</span></h1>
                        </div>
                        <p className="mb-5">Dedicated to providing exceptional construction services that redefine spaces and elevate experiences. Our commitment to quality and innovation sets us apart.</p>
                        <div className="socials text-2xl flex mobile:w-full  laptop:w-1/4 laptop:justify-between">
                            <a href="/contact" className="mr-3" ><FaWhatsapp className="inline-block text-green-600" /></a>
                            <a href="/contact" className="mr-3"><FaLinkedin className="inline-block text-blue-500" /></a>
                            <a href="/contact"><FaInstagram className="inline-block text-rose-500" /></a>
                        </div>
                    </div>
                </div>
                {/* Website Links */}
                <div className="website-links leading-10 text-lg">
                    <h1 className="text-2xl mb-4 font-semibold">Our Company</h1>
                    <ul>
                        <li>
                            <a href="/about" className="hover:text-orange-200">Who We Are</a>

                        </li>
                        <li>
                            <a href="/services" className="hover:text-orange-200">Services We Provide</a>

                        </li>
                        <li>
                            <a href="/projects" className="hover:text-orange-200">What We Have Done</a>

                        </li>
                        <li>
                            <a href="/contact" className="hover:text-orange-200">Contact Us</a>

                        </li>
                    </ul>
                </div>
                {/* Services Links */}
                <div className="services-links leading-8 text-lg">
                    <h1 className="text-2xl mb-4 font-semibold">Our Services</h1>
                    <p >Architecture</p>
                    <p >Exterior Design</p>
                    <p >Landscape Design</p>
                    <p >Site Planning</p>
                </div>
                {/* Contact Details */}
                <div className="contact-details leading-8 text-lg">
                    <h1 className="text-2xl mb-4 font-semibold">Contact Details</h1>
                    <p >+91 9908212850</p>
                    <p >12-2-826/A/46</p>
                    <p >Near Mehdipatnam Bus Depot, Hyderabad</p>
                    <p >info@aplusconstructs.com</p>
                </div>
            </div>
        </footer>);
}

export default Footer;