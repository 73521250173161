import { FaArrowRight } from "react-icons/fa6";
import ServicesSection from "../components/Services";
import { MdArchitecture } from "react-icons/md";
import { SiMaterialdesignicons } from "react-icons/si";
import { TfiLayoutSidebar2 } from "react-icons/tfi";
import { TfiLayoutSidebarLeft } from "react-icons/tfi";
import { FaChartArea } from "react-icons/fa";
import { GiWoodenCrate } from "react-icons/gi";
import welcomeImg0 from "../assets/images/welcome0.jpg"
import welcomeImg from "../assets/images/welcome.jpg"
import Contact from "./Contact";
import About from "./About";
// import constructionImage from "../assets/images/construction.jpg"
const specials = {
    architecture: {
        title: "Architecture",
        description: "Crafting visionary architectural designs that blend functionality with timeless aesthetics. Our goal is to create spaces that inspire and elevate.",
        icon: <MdArchitecture />
    },
    landscape: {
        title: "Landscape Design",
        description: "Transforming outdoor areas into lush, inviting landscapes that harmonize with nature and enhance the beauty of every project.",
        icon: <SiMaterialdesignicons />
    },
    exterior: {
        title: "Exterior Design",
        description: "Delivering exterior designs that merge form and function, creating a lasting first impression that stands the test of time and weather.",
        icon: <TfiLayoutSidebarLeft />
    },
    site: {
        title: "Site Planning",
        description: "Meticulous site planning services to optimize space, ensure regulatory compliance, and lay a strong foundation for successful projects.",
        icon: <FaChartArea />
    },
    furniture: {
        title: "Furniture Design",
        description: "Custom furniture solutions that align with your aesthetic and functional needs, designed to add character and comfort to any space.",
        icon: <GiWoodenCrate />
    },
    interior: {
        title: "Interior Design",
        description: "Creating interior spaces that resonate with elegance, style, and practicality. We tailor each element to reflect your unique taste.",
        icon: <TfiLayoutSidebar2 />
    },
};



function Home() {
    return (
        <section className=" relative z-10  " >

            {/* Landing Section */}
            <main className="  w-full  relative z-20 overflow-hidden  tablet:px-10 ">
                <div className="bg-landing "></div>
                <div className="px-8 py-44  relative tablet:w-3/4 mx-auto  text-center  z-10 ">
                    <div className="text-4xl tablet:text-5xl large-desktop:mt-10 laptop:text-6xl font-bold text-white">
                        <h1 className="mb-8 tablet:mb-10">Excellence in Every Detail</h1>
                        <h1 className="mb-8 tablet:mb-10">Integrity in Every Project</h1>
                        <h1 className="mb-8 tablet:mb-10">Value in Every Step</h1>
                        <p className="text-xl mt-3 font-normal">
                            Building spaces that embody quality, innovation, and timeliness, from groundbreaking to grand opening.
                        </p>
                    </div>
                    <div className="btn-container mt-20 laptop:mt-16">
                        <a href="/contact" className="bg-orange-500 hover:bg-orange-100 hover:text-cyan-900 font-semibold inline-block transform hover:scale-105  border-2 text-white px-10 py-5 rounded-full transition-all duration-200 group">
                            Get Your Free Consultation <FaArrowRight className="inline-block ml-2 animate-pulse group-hover:animate-none transition-transform duration-200 group-hover:translate-x-2" />
                        </a>
                    </div>
                </div>
                {/* <div className="hidden laptop:block w-[50%] rounded-lg overflow-hidden shadow-lg">
                    <img src={constructionImage} alt="Project Preview" className="object-cover " />
                </div> */}
            </main>
            <div className="auto-slider bg-yellow-500 text-black py-5 text-2xl relative z-20 hidden tablet:flex">
                
                <ul className="">
                    <li className="flex tablet:gap-[15rem]">
                        <span >A+ Constructs</span>
                        <span >9908212850</span>
                        <span >info@aplusconstructs.com</span>
                        <span >Hyderabad, India</span>
                    </li>
                    <li className="hidden tablet:flex  gap-[15rem]">
                        <span >A+ Constructs</span>
                        <span >9908212850</span>
                        <span >info@aplusconstructs.com</span>
                        <span >Hyderabad, India</span>
                    </li>
                    <li className="hidden tablet:flex gap-[15rem]">
                        <span >A+ Constructs</span>
                        <span >9908212850</span>
                        <span >info@aplusconstructs.com</span>
                        <span >Hyderabad, India</span>
                    </li>
                    <li className="hidden tablet:flex gap-[15rem]">
                        <span >A+ Constructs</span>
                        <span >9908212850</span>
                        <span >info@aplusconstructs.com</span>
                        <span >Hyderabad, India</span>
                    </li>
                   
                    
                </ul>

                
            </div>
            {/* Welcome Section*/}
            <section className="  px-10 relative overflow-hidden  ">
            <div className="bg-tiles hidden tablet:block"></div>

                <div className="grid py-10 grid-cols-1 pt-20  laptop:grid-cols-2 justify-items-center items-center relative z-10">

                    <div className="img2-container relative border-4 border-black h-fit">
                        <img src={welcomeImg0} alt="" className="relative top-6 left-6 object-cover w-[500px] h-full" />

                    </div>
                    <div className="welcome leading-snug justify-self-start  pt-10 tablet:pt-0 bg-white p-10 rounded-3xl">
                        <div className="container mx-auto  ">
                            <h1 className="text-4xl font-bold mb-4 tablet:mt-5">Welcome to <p className="text-5xl mt-2">A+ CONSTRUCTS</p></h1>
                            <p className="text-lg mb-6">
                                Where every foundation, wall, and beam is built with your dreams in mind! We’re not just builders;
                                we’re partners in creating the spaces where your most meaningful moments happen,
                                whether it's a home filled with warmth or a workspace that inspires.
                            </p>

                            <h2 className="text-2xl font-semibold mb-3 ">Why Choose Us?</h2>
                            <p className="mb-4">
                                At <span className="bg-yellow-300">A+ CONSTRUCTS</span>, we pride ourselves on a blend of <strong>top-notch craftsmanship</strong>,
                                <strong>dedication to safety</strong>, and <strong>client-focused service</strong>.
                                Our team of skilled professionals works to ensure each project reflects quality and longevity.
                            </p>

                            <h2 className="text-2xl font-semibold mt-8 ">Our Commitment to Your Well-Being</h2>
                            <p className="mb-6">
                                We prioritize your well-being by adhering to high safety standards. We use top-grade materials,
                                follow strict protocols, and incorporate sustainable practices to ensure that your project is
                                both beautiful and responsibly built.
                            </p>

                            <h2 className="text-2xl font-semibold mb-4 ">Ready to Bring Your Vision to Life?</h2>
                            <p className="mb-6">
                                We’d love to chat about your project ideas! Contact us today for a consultation or quote:
                            </p>

                            <div className="space-y-2">
                                <p>📞 <strong>Phone</strong>: +91 9908212850</p>
                                <p>📧 <strong>Email</strong>: info@aplusconstructs.com</p>
                                <p>📍 <strong>Office</strong>: 12-2-826/A/46, Near Mehdipatnam Bus Depot, Hyderabad</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ServicesSection />
            {/* Banner for Consultation */}
            <section className="relative z-10 banner py-10 px-10 bg-cyan-50 bg-opacity-80">
                <div className="text-center leading-snug">
                    <h1 className="text-4xl font-bold mb-4 mobile:mb-5">Schedule Your Free Consultation</h1>
                    <p className="text-lg mb-4 mobile:mb-8">
                        Discover how our expertise can bring your vision to life. Book a consultation to explore personalized solutions tailored to your project’s unique needs.
                    </p>
                    <a href="/contact" className="bg-orange-500 text-white py-5 px-10 inline-block rounded-full hover:scale-110 transition-all duration-200 group">
                        <FaArrowRight className="mr-5 inline-block group-hover:translate-x-5 transition-all duration-200" /> Book an Appointment
                    </a>
                </div>
            </section>
            {/* Specialization section */}
            <section className="relative z-10 py-10 tablet:px-20 px-10 mobile:py-10 w-full flex justify-around flex-col-reverse items-center laptop:items-start laptop:flex-row gap-x-10 gap-y-10 tablet:gap-y-0 mb-10  ">
                <div className="content laptop:w-2/4 mt-10 laptop:mt-0  ">
                    <h1 className="text-4xl tablet:text-5xl font-bold mb-4 laptop:mb-10">Our Specialization</h1>
                    <p className="text-lg mb-8">
                        We focus on delivering excellence across a range of specialized construction and design services. Each area of our expertise is crafted to bring your vision to life with precision, quality, and attention to detail.
                    </p>
                    <div className="grid grid-cols-1 tablet:grid-cols-2 gap-6">
                        {Object.values(specials).map((item, index) => (
                            <div key={index} className="flex items-start justify-center gap-4 ">

                                <div className="content">
                                    <div className=" tablet:text-2xl flex  tablet:justify-start items-center tablet:mb-3 mobile:mb-2">
                                        <div className="mr-2">

                                            {item.icon}
                                        </div>
                                        <h2 className="text-xl font-semibold">{item.title}</h2>
                                    </div>
                                    <p className="">{item.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="img2-container relative border-4 border-black w-fit h-fit ">
                    <img src={welcomeImg} alt="" className="relative top-6 left-6 object-cover w-[500px]" />

                </div>
            </section>
            {/* Banner for Projects */}
            <section className="relative z-10 mt-10 tablet:mt-0 banner bg-cyan-50  laptop:p-20  tablet:p-14 py-10 px-5">
                <div className=" leading-snug flex flex-col  tablet:flex-row justify-between ">
                    <div className="">
                        <h1 className="text-4xl font-bold mb-4">Explore Our Recent Projects</h1>

                        <p className="text-lg mb-4 ">
                            Discover our portfolio of successful projects where innovation meets precision. From concept to completion, we deliver spaces that inspire and endure.
                        </p>
                    </div>
                    <div className="tablet:self-center tablet:text-end w-1/4  ">

                        <a href="/projects" className="bg-orange-500 text-white hover:scale-110 transition-all duration-200 group  py-5 px-10 rounded-md inline-block ">
                            View All Projects <FaArrowRight className="mr-2 inline-block group-hover:translate-x-5 duration-200 transition-all" />
                        </a>
                    </div>
                </div>
            </section>
            <div className="bg-white relative z-10 ">

            <Contact pt="false"/>
            </div>
            <div className="about relative z-10 bg-white">
                <About pt="false"/>
            </div>

        </section>
    );
}

export default Home;
